import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteProtectionService {

  private deleteObserver: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public allowDelete: boolean = false;

  constructor() {
    this.deleteObserver.subscribe(val => {
      this.allowDelete = val;
    });
  }

  public setAllowDelete(val: boolean): void {
    this.deleteObserver.next(val);
  }

  public getAllowDelete(): boolean {
    return this.allowDelete;
  }
  
  public getAllowDeleteObserver(): BehaviorSubject<boolean> {
    return this.deleteObserver;
  }
}
