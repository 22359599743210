import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Setting } from '../model/Setting';
import { DeleteProtectionService } from '../services/delete-protection.service';
import { SettingsService } from '../services/settings.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private userSerivce: UserService,
    private router: Router,
    private deleteProtectionService: DeleteProtectionService,
    private settingService: SettingsService,
  ) { }

  public async canActivate(): Promise<boolean> {
    await this.checkAllowDelete();
    let canActv: boolean = false;

    await (await this.userSerivce.getUserSelfSetLocale()).toPromise()
    .then(res => {
      if (res == undefined) return;
      canActv = true;
    })
    .catch(err => {
      console.log(err);
      canActv = false;
      this.userSerivce.setBypass(false);
      console.log('[AUTHGUARD]: REDIRECT TO LOGIN');
      this.router.navigate(['/login']);
    });

    return canActv;
  }

  private async checkAllowDelete(): Promise<void> {
    const setting = new Setting();
    setting.key = this.settingService.WAREHOUSE_ALLOW_DELETE;
    this.settingService.getIsoNumberSetting(setting).toPromise()
    .catch(err => console.log(err))
    .then(res => {
      if (res == undefined) {
        this.deleteProtectionService.setAllowDelete(false);
        return;
      };
      if (res.body.value == 'true') {
        this.deleteProtectionService.setAllowDelete(true);
      } else {
        this.deleteProtectionService.setAllowDelete(false);
      }
    });
  } 
}
