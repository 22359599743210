import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GroupAllResponse } from '../model/Responses/GroupAllResponse';
import { GroupResponse } from '../model/Responses/GroupResponse';
import { DataService } from './data.service';
import { Group } from '../model/Group';
import { PermissionAllResponse } from '../model/Responses/PermissionAllResponse';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) { }

  public addGroup(group: Group): Observable<GroupResponse> {
    return this.http.post<GroupResponse>(`${this.dataService.getBackendURL()}/createGroup`, group, this.dataService.getHttpOptions());
  }

  public deleteGroup(group: Group): Observable<any> {
    return this.http.post(`${this.dataService.getBackendURL()}/deleteGroup`, {id: group.id}, this.dataService.getHttpOptions());
  }

  private getAllGroupsWithToken(token: string): Observable<GroupAllResponse> {
    return this.http.get<GroupAllResponse>(`${this.dataService.getBackendURL()}/getGroups`, this.dataService.getHttpOptions(token));
  }

  public getAllGroups(): Observable<GroupAllResponse> {
    return this.http.get<GroupAllResponse>(`${this.dataService.getBackendURL()}/getGroups`, this.dataService.getHttpOptions());
  }

  public getGroupById(id: number): Observable<GroupResponse> {
    return this.http.get<GroupResponse>(`${this.dataService.getBackendURL()}/getGroup/${id}`, this.dataService.getHttpOptions());
  }

  public getPermissions(): Observable<PermissionAllResponse> {
    return this.http.get<PermissionAllResponse>(`${this.dataService.getBackendURL()}/getPermissionList`, this.dataService.getHttpOptions());
  }

  public updateGroup(group: Group): Observable<GroupResponse> {
    return this.http.post<GroupResponse>(`${this.dataService.getBackendURL()}/changeGroup`, group, this.dataService.getHttpOptions());
  }

}
