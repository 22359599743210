import {EventEmitter, Injectable} from "@angular/core";
import {DataService} from "./data.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Setting} from "../model/Setting";
import {GenericResponse} from '../model/Responses/GenericResponse';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    public ISO_NUMBER_KEY = 'ISO_NUMBERS';
    public ISO_PURCHASING_NUMBER_KEY = 'ISO_NUMBERS_PURCHASING';
    public TAX_KEY = 'system.price.tax';
    public MATERIAL_PRICE_KEY = 'system.price.additional'; 
    public SURFACE_PRICE_KEY = 'system.price.surface'; 
    public PRODUCTION_PRICE_KEY = 'system.price.production'; 
    public CRATE_KEY = 'system.settings.crates';
    public DEPARTMENT_KEY = 'system.settings.departments';
    public TOLERANCE_KEY = 'system.settings.warehouse.tolerances';
    public WAREHOUSE_ALLOW_DELETE = 'system.settings.warehouse.allowdelete';
    public NETWORK_ADDRESS = 'system.settings.network_address';
    public SAWING_HOURLY_RATE = 'price.hourly.saw';
    public MANUAL_WORK_HOURLY_RATE = 'price.hourly.manual-work';
    public MAX_OTHER_WORKTIME = 'timetracking.max.other.worktime';
    public HEATTREATMENT_PRICE_KEY = 'system.price.heattreatment'; 
    public WELDING_PRICE_KEY = 'system.price.welding';
    public EMAIL_SIGNATURE_KEY = 'system.settings.email.signature';
    public COMPANY_CORE_DATA_KEY = 'system.settings.company.core_data';
    public COMPANY_IMAGE_KEY = 'system.settings.company.image';
    // public ISO_NUMBER_SEPARATOR = '%ISO_SEP%';
    // public ISO_VALUE_SEPERATOR = '%ISO_VAL_SEP%';
    public DEFAULT_PLATE_TOLERANCE_1 = 5;
    public DEFAULT_PLATE_TOLERANCE_2 = 0;
    public DEFAULT_PLATE_TOLERANCE_3 = 0;
    private REST_PREFIX = '/settings';

    public logo: string = '';
    public companyName: string = '';

    constructor(
        private dataService: DataService,
        private http: HttpClient,
    ) {
        let s = new Setting();
        s.key = this.COMPANY_IMAGE_KEY;
        this.getPublicSetting(s).toPromise()
            .catch(err => {
                console.log(err);
            })
            .then((res: GenericResponse<Setting>) => {
                if(res != undefined && res.status == 200 && res.body != undefined && res.body.value != undefined && res.body.value.length > 0){
                    this.logo = res.body.value;
                }
            });
    }

    public createIsoNumberSetting(setting: Setting): Observable<any> {
        return this.http.put(`${this.dataService.getBackendURL()}${this.REST_PREFIX}/create`, setting, this.dataService.getHttpOptions());
    }


    public updateIsoNumberSetting(setting: Setting): Observable<any> {
        return this.http.post(`${this.dataService.getBackendURL()}${this.REST_PREFIX}/${setting.key}/update`, setting, this.dataService.getHttpOptions());
    }

    public getIsoNumberSetting(setting: Setting): Observable<any> {
        return this.http.get(`${this.dataService.getBackendURL()}${this.REST_PREFIX}/${setting.key}`, this.dataService.getHttpOptions());
    }

    public getPublicSetting(setting: Setting): Observable<any> {
        return this.http.get(`${this.dataService.getBackendURL()}${this.REST_PREFIX}/public/${setting.key}`, this.dataService.getHttpOptions());
    }
}
