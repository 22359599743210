import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CustomContextMenuItem} from '../../directives/customContextMenu.directive';

@Component({
  selector: 'app-custom-context-menu',
  templateUrl: './custom-context-menu.component.html',
  styleUrls: ['./custom-context-menu.component.scss'],
})
export class CustomContextMenuComponent implements OnInit {

  @Input() public items: CustomContextMenuItem[];
  @Output() public eventEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() public data: any;

  constructor() { }

  public onClick(item: CustomContextMenuItem): void {
    if (this.eventEmitter != undefined) {
      this.eventEmitter.next(item.event);
    }
  }

  @HostListener('contextmenu', ['$event']) public cancelContextMenu(e){
    e.preventDefault();
    e.stopPropagation();
  }

  ngOnInit(): void {
  }

}
