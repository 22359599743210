
export class UserWorkloadString {

    public active: number;
    public city?: string;
    public email: string;
    public firstName: string;
    public groupId: number;
    public hourlyRate: string;
    public houseNumber?: string;
    public id: number;
    public lastName: string;
    public mobileNumber?: string;
    public telephone?: string;
    public internalTelephone?: string;
    public notes?: Array<string>;
    public password: string;
    public postalCode?: string;
    public salt?: string;
    public street?: string;
    public token: string;
    public userName: string;
    public weeklyWorkload: string;
    public yearlyVacationDays: Map<string, string>;
    public emailTemplate?: string;
    public remoteAccessAllowed: boolean;
    public entryDateTimestamp: number;

}
