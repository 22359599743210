import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {CustomContextMenuComponent} from './components/custom-context-menu/custom-context-menu.component';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
      AppComponent,
      CustomContextMenuComponent
  ],
  entryComponents: [
      CustomContextMenuComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BarcodeScanner,
    LocalNotifications,
    {provide: LOCALE_ID, useValue: 'de'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
