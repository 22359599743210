import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Permission } from '../model/Permission';
import {Platform} from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private backendURL: string = environment.backendURL;
  private token: string;
  private userPermissions: Subject<Permission[]> = new BehaviorSubject<Permission[]>(new Array<Permission>());

  public extendedLoginToken = "TOKEN_EX";

  constructor(
    private router: Router,
    private storage: Storage,
  ) { }

  public getBackendURL(): string {
    return this.backendURL;
  }

  public clearData(): void {
    this.token = undefined;
    this.userPermissions.next(new Array<Permission>());
      if(localStorage.getItem(this.extendedLoginToken) != undefined
          && localStorage.getItem(this.extendedLoginToken) != null){
          localStorage.setItem(this.extendedLoginToken,null);
      }
  }

  public getToken(): string {
    return this.token;
  }

  public async getStorageToken(): Promise<string> {
    return this.storage.get('token');
  }

  public setToken(token: string) {
    this.storage.set('token', token);
    this.token = token;
  }


  public getHttpOptions(token?: string): object {
    if (token !== undefined) {
      return {
        headers: new HttpHeaders(
          {
            'X-Auth-Token': token,
            'Access-Control-Allow-Origin': '*',
            'uploadedAt': `${new Date().getTime()}`,
          }),
      };
    } else if (this.token !== undefined) {
      return {
        headers: new HttpHeaders(
          {
            'X-Auth-Token': this.token,
            'Access-Control-Allow-Origin': '*',
            'uploadedAt': `${new Date().getTime()}`,
          }),
      };
    }
    return {};
  }

  public getHttpPDFOptions(token?: string): object {
    if (token !== undefined) {
      return {
        headers: new HttpHeaders(
            {
              'X-Auth-Token': token,
              'Access-Control-Allow-Origin': '*',
            }),
        responseType: 'blob',
        observe: 'response' as 'body',
      };
    } else if (this.token !== undefined) {
      return {
        headers: new HttpHeaders(
            {
              'X-Auth-Token': this.token,
              'Access-Control-Allow-Origin': '*',
            }),
        responseType: 'blob',
        observe: 'response' as 'body',
      };
    } else {
      this.router.navigate(['login']);
    }
  }

  public getHttpMultipartOptionsImageUpload(toId: number, createdAt: number, customOptionsPart?: any, token?: string): object {
    if (token !== undefined) {
      return {
        headers: new HttpHeaders(
            {
              'X-Auth-Token': token,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p',
              'toId': ''+toId,
              'createdAt': ''+createdAt,

            }),
        customOptionsPart,
      };
    } else if (this.token !== undefined) {
      return {
        headers: new HttpHeaders(
            {
              'X-Auth-Token': this.token,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p',
              'toId': ''+toId,
              'createdAt': ''+createdAt,
            }),
      };
    } else {
      this.router.navigate(['login']);
    }
  }

  public getHttpMultipartOptionsImageUploadTasks(uploadedAt: number, customOptionsPart?: any, token?: string): object {
    if (token !== undefined) {
      return {
        headers: new HttpHeaders(
            {
              'X-Auth-Token': token,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p',
              'uploadedAt': ''+uploadedAt,

            }),
        customOptionsPart,
      };
    } else if (this.token !== undefined) {
      return {
        headers: new HttpHeaders(
            {
              'X-Auth-Token': this.token,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p',
              'uploadedAt': ''+uploadedAt,
            }),
      };
    } else {
      this.router.navigate(['login']);
    }
  }

  public getHttpMultipartOptionsItemFileUpload(createdAt: number, customOptionsPart?: any, token?: string): object {
    if (token !== undefined) {
      return {
        observe: 'response',
        headers: new HttpHeaders(
            {
              'X-Auth-Token': token,
              'Access-Control-Allow-Origin': '*',
            //'Content-Type': 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p',
              'uploadedAt': ''+createdAt,

            }),
        customOptionsPart,
      };
    } else if (this.token !== undefined) {
      return {
        observe: 'response',
        headers: new HttpHeaders(
            {
              'X-Auth-Token': this.token,
              'Access-Control-Allow-Origin': '*',
              //'Content-Type': 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p',
              'uploadedAt': ''+createdAt,
            }),
      };
    } else {
      this.router.navigate(['login']);
    }
  }

  public setUserPermissions(permissions: Permission[]): void {
    this.userPermissions.next(permissions);
  }

  public userHasPermission(key: string): boolean {
    let result = false;
    this.userPermissions.subscribe({
      next: permissions => {
        permissions.forEach(perm => {
          if (key === perm.key) {
            result = true;
          }
        });
      },
    });
    return result;
  }

  public getUserPermissions(): Observable<Permission[]> {
    return this.userPermissions;
  }

  public getMobileDevices(): string[] {
    return [
      'android',
      'mobile',
      'mobileweb',
      'tablet',
      'ios',
      'ipad',
      'iphone',
    ];
  }

  public getDesktopDevices(): string[] {
    return [
      'desktop',
    ];
  }

  public checkIfMobile(platform: Platform): boolean {
    let hasMobile = false;
    let isAndroid = false;
    let isIOs = false;

    for (const pname of this.getMobileDevices()) {
      if (platform.platforms().indexOf(pname) !== -1) {
        hasMobile = true;
      }
    }
    if (platform.platforms().indexOf('android') !== -1) {
      isAndroid = true;
    }
    if (platform.platforms().indexOf('ios') !== -1) {
      isIOs = true;
    }
    if ((isAndroid || isIOs) && hasMobile) {
      return true;
    }
    return false;
  }

}
