import { Injectable } from '@angular/core';
import { User } from '../model/User';
import { UserSelfResponse } from '../model/Responses/UserSelfResponse';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserAllResponse } from '../model/Responses/UserAllResponse';
import { Router } from '@angular/router';
import { UserAddResponse } from '../model/Responses/UserAddResponse';
import { Storage } from '@ionic/storage';
import { DataService } from './data.service';
import { GroupService } from './group.service';
import { PermissionAllResponse } from '../model/Responses/PermissionAllResponse';
import { GroupResponse } from '../model/Responses/GroupResponse';
import { Permission } from '../model/Permission';
import {UserWorkloadString} from "../model/UserWorkloadString";

@Injectable({
  providedIn: 'root'
})
export class UserService {


  private user: Subject<User> = new BehaviorSubject<User>(new User());

  private documentArchiveBypassKey = "documentArchiveBypassKey";
  private canUnlockMonth: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: Storage,
    private dataService: DataService,
    private groupService: GroupService,
  ) {}

  public getUser(): Observable<User> {
    return this.user;
  }

  public getUserSelf(token?: string): Observable<UserSelfResponse> {
    if (this.dataService.getToken() === undefined && token === undefined) {
      return;
    }
    return this.http.get<UserSelfResponse>(`${this.dataService.getBackendURL()}/getSelf`, this.dataService.getHttpOptions(token));
  }

  public async getUserSelfSetLocale(): Promise<Observable<any>> {
    const token = await this.storage.get('token');
    return this.http.get<UserSelfResponse>(`${this.dataService.getBackendURL()}/getSelf`, this.dataService.getHttpOptions(token));
  }

  public getCopyOfUser(): User {
    let tempUser: User;
    this.user.subscribe((user: User) => tempUser = user);
    return Object.assign(new User(), tempUser);
  }

  public setUser(user: User): void {
    if (user !== undefined) {
      this.dataService.setToken(user.token);
      this.setUserPermissions(user);
    }
    this.user.next(user);
  }

  private setUserPermissions(user: User): void {
    if (user.id === undefined) {
      return;
    }
    this.groupService.getGroupById(user.groupId).subscribe((res: GroupResponse) => {
      this.groupService.getPermissions().subscribe((res2: PermissionAllResponse) => {
        const permissions = new Array<Permission>();
        res.body.permissions.forEach((id: number) => {
          for (const permission of res2.body) {
            if (permission.id === id) {
              permissions.push(permission);
            }
          }
        });
        this.dataService.setUserPermissions(permissions);
      });
    });
  }

  public getAllUsers(getInactive: boolean = false): Observable<UserAllResponse> {
    return this.http.get<UserAllResponse>(`${this.dataService.getBackendURL()}/getAllUsers/${getInactive}/`, this.dataService.getHttpOptions());
  }

  public getAllUsersBasic(): Observable<UserAllResponse> {
    return this.http.get<UserAllResponse>(`${this.dataService.getBackendURL()}/getAllUserBasics`, this.dataService.getHttpOptions());
  }

  public setNotes(notes: string[]): Observable<any> {
    let wrapper = {
      notes
    };
    return this.http.post(`${this.dataService.getBackendURL()}/setNotes`,wrapper, this.dataService.getHttpOptions());
  }

  public changeSelf(user: User, newPassword?: string): Observable<any> {
    const obj = new UserWorkloadString();

    obj.id = user.id;
    obj.email = user.email;
    obj.userName = user.userName;
    obj.firstName = user.firstName;
    obj.lastName = user.lastName;
    obj.mobileNumber = user.mobileNumber;
    obj.telephone = user.telephone;
    obj.internalTelephone = user.internalTelephone;
    obj.remoteAccessAllowed = user.remoteAccessAllowed;
    obj.street = user.street;
    obj.houseNumber = user.houseNumber;
    obj.city = user.city;
    obj.postalCode = user.postalCode;
    obj.groupId = user.groupId;
    obj.hourlyRate = (''+user.hourlyRate).replace(",",".");
    obj.weeklyWorkload = (''+user.weeklyWorkload).replace(",",".");
    obj.yearlyVacationDays = user.yearlyVacationDays;

    if (newPassword) {
      obj.password = newPassword;
    } else {
      obj.password = '';
    }

    return this.http.post(`${this.dataService.getBackendURL()}/changeSelf`, obj, this.dataService.getHttpOptions());
  }

  public changeUser(newUser: User, newPassword?: string): Observable<any> {
    let user: User;
    this.getUser().subscribe((userr: User) => user = userr);

    const obj = new UserWorkloadString();

    obj.id = newUser.id;
    obj.email = newUser.email;
    obj.userName = newUser.userName;
    obj.firstName = newUser.firstName;
    obj.lastName = newUser.lastName;
    obj.mobileNumber = newUser.mobileNumber;
    obj.telephone = newUser.telephone;
    obj.internalTelephone = newUser.internalTelephone;
    obj.street = newUser.street;
    obj.remoteAccessAllowed = newUser.remoteAccessAllowed;
    obj.houseNumber = newUser.houseNumber;
    obj.city = newUser.city;
    obj.postalCode = newUser.postalCode;
    obj.groupId = newUser.groupId;
    obj.hourlyRate = (''+newUser.hourlyRate).replace(",",".");
    obj.weeklyWorkload = (''+newUser.weeklyWorkload).replace(",",".");
    obj.yearlyVacationDays = newUser.yearlyVacationDays;
    obj.emailTemplate = newUser.emailTemplate;
    obj.entryDateTimestamp = newUser.entryDateTimestamp;

    if (newPassword) {
      obj.password = newPassword;
    } else {
      obj.password = '';
    }

    return this.http.post(`${this.dataService.getBackendURL()}/changeUser`, obj, this.dataService.getHttpOptions());
  }

  public addNoteToUser(user: User, note: string): Observable<any> {
    if (user.notes !== undefined) {
      user.notes.push(note);
    } else {
      user.notes = new Array<string>(note);
    }
    return this.http.post(`${this.dataService.getBackendURL()}/changeSelf`, user, this.dataService.getHttpOptions());
  }

  public removeNoteFromUser(user: User, noteToRemove: string): Observable<any> {
    const temp = new Array<string>();
    const index = user.notes.indexOf(noteToRemove);
    for (let i = 0; i < user.notes.length; i++) {
      if (i !== index) {
        temp.push(user.notes[i]);
      }
    }
    user.notes = temp;
    return this.http.post(`${this.dataService.getBackendURL()}/changeSelf`, user, this.dataService.getHttpOptions());
  }

  public createUser(user: User): Observable<UserAddResponse> {
    return this.http.post<UserAddResponse>(`${this.dataService.getBackendURL()}/createUser`, user, this.dataService.getHttpOptions());
  }

  public deleteUser(user: User): Observable<any> {
    return this.http.post(`${this.dataService.getBackendURL()}/deleteUser`, {id: user.id}, this.dataService.getHttpOptions());
  }

  public activateUser(user: User): Observable<any> {
    return this.http.get(`${this.dataService.getBackendURL()}/activateUser/${user.id}`, this.dataService.getHttpOptions());
  }

  public getSelfFromToken(token: string): Observable<UserSelfResponse> {
    return this.http.get<UserSelfResponse>(`${this.dataService.getBackendURL()}/getSelf`, this.dataService.getHttpOptions(token));
  }

  public getUserById(id: number): Observable<UserSelfResponse> {
    return this.http.get<UserSelfResponse>(`${this.dataService.getBackendURL()}/getUser/${id}`, this.dataService.getHttpOptions());
  }

  public isAdmin(): Observable<any> {
    return this.http.get<any>(`${this.dataService.getBackendURL()}/isAdmin`, this.dataService.getHttpOptions());
  }

  public setBypass(val: boolean): void{
    if(val){
      sessionStorage.setItem(this.documentArchiveBypassKey,"1");
    }else{
      sessionStorage.setItem(this.documentArchiveBypassKey,"0");
    }
  }

  public getBypass(): boolean{
    return sessionStorage.getItem(this.documentArchiveBypassKey) === "1";
  }

  public setIgnoredUsers(ignoredUsers): Observable<any>{
    return this.http.post(`${this.dataService.getBackendURL()}/setIgnoredUsers`, {ignoredUsers}, this.dataService.getHttpOptions());
  }

  public setCanUnlockMonth(value: boolean): void {
    this.canUnlockMonth = value;
  }

  public getCanUnlockMonth(): boolean {
    return this.canUnlockMonth;
  }

}
